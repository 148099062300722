import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
import JwtPayloadViewer from '../../../components/JwtPayloadViewer';
import SwedishBankIdJwtSnippet from '../../../snippets/jwt-payloads/swedish-bankid';
import template from './files/swedbank-verksamhetsinfo-bankid-template.pdf';
export const _frontmatter = {
  "product": "verify",
  "category": "eIDs",
  "sort": 0,
  "title": "Swedish BankID",
  "subtitle": "Learn more about Swedish BankID token contents, how to create test users and how to gain access to production."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`JWT/Token example`}</h2>
    <SwedishBankIdJwtSnippet mdxType="SwedishBankIdJwtSnippet" />
    <h3>{`Optional claims for native BankID signature verification`}</h3>
    <p>{`If your use case requires verifying the signature (which is `}<a href="https://www.bankid.com/utvecklare/guider/teknisk-integrationsguide/verifiera-signaturer" target="_blank">{`not required, but possible`}</a>{`), you have an option to request the evidence data as returned by the underlying e-ID provider.
This can be done by adding `}<inlineCode parentName="p">{`scope=openid evidence`}</inlineCode>{` (or `}<inlineCode parentName="p">{`login_hint=scope:evidence`}</inlineCode>{`) to your authorize request.`}</p>
    <p>{`With this configuration, the issued JWT will contain two additional claims: `}<inlineCode parentName="p">{`evidence`}</inlineCode>{` and `}<inlineCode parentName="p">{`ocspResponse`}</inlineCode>{`:`}</p>
    <JwtPayloadViewer payload={{
      "identityscheme": "sebankid",
      "nameidentifier": "1373c272b61a4cb588b29c44883fe62f",
      "sub": "{1373c272-b61a-4cb5-88b2-9c44883fe62f}",
      "ssn": "196802020575",
      "name": "Terne Paulsen",
      "given_name": "Terne",
      "family_name": "Paulsen",
      "ipaddress": "77.241.128.160",
      "country": "SE",
      "evidence": "PD94bWwgdmVyc2lvbj0i...5hdHVyZT4=",
      "ocspResponse": "MIIHdgoBAKCCB28wggdrBgk...uSbCquVT1J"
    }} mdxType="JwtPayloadViewer" />
    <p><em parentName="p">{`The full content of `}<inlineCode parentName="em">{`evidence`}</inlineCode>{` and `}<inlineCode parentName="em">{`ocspResponse`}</inlineCode>{` is omitted for brevity.`}</em></p>
    <h4>{`Avoiding excessively large JWT payload`}</h4>
    <p>{`When included in the payload, `}<inlineCode parentName="p">{`evidence`}</inlineCode>{` and `}<inlineCode parentName="p">{`ocspResponse`}</inlineCode>{` claims will significantly increase the size of the JWT token, potentially causing it to exceed the size limitations of an HTTP header. The bloated JWTs would thus become unusable as Bearer tokens for accessing APIs.`}</p>
    <p>{`To address this concern, consider changing the `}<a href="https://openid.net/specs/oauth-v2-multiple-response-types-1_0.html" target="_blank">{`response type`}</a>{` in your `}<a parentName="p" {...{
        "href": "/verify/getting-started/oidc-intro/#example-authentication-url"
      }}>{`authorization request`}</a>{` to `}<inlineCode parentName="p">{`response_type=code id_token`}</inlineCode>{`.`}</p>
    <p>{`In this case, a more compact ID Token with only essential claims will be issued from the Token endpoint. The client can subsequently fetch all data about the user from the Userinfo endpoint.`}</p>
    <p><em parentName="p">{` With `}<inlineCode parentName="em">{`code id_token`}</inlineCode>{` response type, the ID Token from the token endpoint will always be the same (and have nothing but a `}<inlineCode parentName="em">{`sub`}</inlineCode>{` that is user-specific). In contrast, when using the `}<inlineCode parentName="em">{`response_type=code`}</inlineCode>{`, the full ID Token is returned immediately in exchange for code.`}</em></p>
    <h2>{`Test users`}</h2>
    <p>{`Swedish BankID test users are created at the `}<a href="https://demo.bankid.com/" target="_blank">{`demo web site`}</a>{`.   `}</p>
    <p>{`For more information on how to configure your device for test and setup test users: `}<a href="https://www.bankid.com/en/utvecklare/test/skaffa-testbankid" target="_blank">{`Get a test BankID`}</a></p>
    <p><em parentName="p">{`Note`}</em>{` that, as is also described on the website, using test BankID users does require a reconfiguration of the BankID application. This means it cannot be used for real BankID. So if you are Swedish and already have BankID on your phone, you may want to use a spare phone for testing.`}</p>
    <h2>{`BankID Phone Authentication`}</h2>
    <a href="https://www.bankid.com/en/foretag/telephone-identification" target="_blank">BankID in telephone calls</a> facilitates user authentication during a phone call.
    <h3>{`Overview`}</h3>
    <p>{`The Phone authentication flow is designed for scenarios where a user is talking with your customer service agent over the phone, and you need to verify that the caller is who they say they are.
This is commonly used when a customer contacts their bank or when a call center agent needs to verify the caller's identity.`}</p>
    <h4>{`The process looks as follows:`}</h4>
    <ol>
      <li parentName="ol">{`SSN Input: The customer service agent enters the user's personal identification number (SSN) in their system, or the user is prompted to input their SSN via an automated PBX system.`}</li>
      <li parentName="ol">{`Authentication request: The customer service agent (if applicable) indicates whether the call was initiated by the user or the company they represent, then initiates authentication request. `}</li>
      <li parentName="ol">{`Security check: The Swedish BankID app displays a phone icon and the company’s name, prompting the user with a Yes/No security check question.`}</li>
      <li parentName="ol">{`User Response: If the user selects Yes, they can proceed by identifying themselves with a security code or biometrics. If the user selects No, they can cancel the identification process.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "768px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "65.10416666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAACo0lEQVQ4y22T2U8TURSH+f/0QaMP4haj0fjkmw9qFBOL3QGBtoImKsZgqCxxIRCWbqaUBCIIKQjUmdZOF1qW0rRluvCZe0eCoCf5zd3m983JnXOaDg4OEGo0hBpSYi1CzOv1+l8yzk56DtcimqrVKuVymUqlIiXmpVIJsV+rCdVoNAygXtXl/v7+/jGPeF+sBbQpFtvA6bTR2dlBe7sTh8OGy9VNpVJG0xIkkyqKss6vhMLy0gK7hV1mZsLYHVbp6XjWhtnSitc7YGS4sbGByfQEm82K0+nAYrXg9jyXWeRym2Qzaba28uztFdDSaXRdJxQK8uDBfSwWMw6HnUctLXwYHDKAsVgMk8mEy+Wir6+Pnt4XvHz1hlq1ipZKoagqh5HK79A4gFAohNfrZWJiAr/fz8fPo4yOjRvAaDSKz+dDVVUWFhaIx+NMTU5SLBbJ5XIkk0l2dnbY3t6mquvSJIAikXw+TyaTRdM0gsHgEXBubp5SqUwmk6FQ2JNfLRQK8rLFKCTge8WiNIXDYRYXF1lf+8HqSpTV1VUCgcDRHQ4MeJn2BZianmZsfJLh4SGZ4ckwSqVBJBJBjcfJ71bIbpVIp7NHQEVRcLtdeNxuPB433V1dvO/vl9n9LwR0NhIhFPrK3LcVZuejhMMR/H6fAVxbX8f01Izd0Ybd2Uar2Yqnp5dKuUwqmyOupdEymyTTWdRfGvu6TiAQ5GHLYyxWq5SYDw4NG8DvS8ucuXCF5ms3uXT9Fmebr3Ln7j35l9+NjGLv6cPz1kv36/fYnr9mc2ubkU9fOHWumcs3bkudPn+RTnePARQXrqoKcVWVo6ooJBIJWfkC2qjX5Hgooz5zhieuSp+i/CSVShmdIh7H+9WQ2Bfmw/Yy2rIiz/7t8Tr1Pz39G4/fj3ZEkljUAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "CIBA security check",
          "title": "CIBA security check",
          "src": "/static/ba0df48075a99422f77a15741b80996c/e5715/se-bankid-phone-auth.png",
          "srcSet": ["/static/ba0df48075a99422f77a15741b80996c/8514f/se-bankid-phone-auth.png 192w", "/static/ba0df48075a99422f77a15741b80996c/804b2/se-bankid-phone-auth.png 384w", "/static/ba0df48075a99422f77a15741b80996c/e5715/se-bankid-phone-auth.png 768w", "/static/ba0df48075a99422f77a15741b80996c/4ad3a/se-bankid-phone-auth.png 1152w", "/static/ba0df48075a99422f77a15741b80996c/acf8f/se-bankid-phone-auth.png 1340w"],
          "sizes": "(max-width: 768px) 100vw, 768px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p>{`The example above demonstrates what it looks like when the customer is calling the service. A different security check is shown when the service is calling the customer.`}</p>
    <h3>{`The underlying CIBA flow`}</h3>
    <p>{`Criipto Verify facilitates Swedish BankID phone authentication via the `}<a parentName="p" {...{
        "href": "/verify/getting-started/oidc-intro/#authenticate-with-ciba"
      }}>{`CIBA flow`}</a>{`.`}</p>
    <h3>{`Implementing BankID Phone Authentication`}</h3>
    <p>{`The CIBA flow is only available to traditional server-based web applications (confidential clients) and requires client validation for authentication requests. We'll use `}<a parentName="p" {...{
        "href": "/verify/guides/privatekey-jwt/"
      }}>{`private key JWTs`}</a>{` for authenticaiton in the example below. An alternative (`}<em parentName="p">{`less secure`}</em>{`) approach is to use `}<inlineCode parentName="p">{`client_secret`}</inlineCode>{` for authentication.`}</p>
    <ol>
      <li parentName="ol">{`Make an `}<inlineCode parentName="li">{`HTTP POST`}</inlineCode>{` request to the `}<inlineCode parentName="li">{`/ciba/bc-authorize`}</inlineCode>{` endpoint:`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`# Replace \`$client_assertion\` with your JWT assertion
# Replace \`YOUR_DOMAIN.criipto.id\` with your Criipto domain
# Replace \`SSN\` with a valid Swedish BankID SSN
# Replace \`BINDING_MESSAGE\` with the text that will be presented to the end user
# \`callInitiator\` must be either "RP"("Relying Party", e.g. a customer service agent) or "user", depending on who initiated the call.

HTTP POST https://YOUR_DOMAIN.criipto.id/ciba/bc-authorize
Content-Type: application/x-www-form-urlencoded

scope=openid
&callInitiator=RP
&login_hint=sub:ssn:SSN
&acr_values=urn:grn:authn:se:bankid
&binding_message=BINDING_MESSAGE
&client_assertion_type=urn:ietf:params:oauth:client-assertion-type:jwt-bearer
&client_assertion=$client_assertion
`}</code></pre>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`A successful response will look something like this:`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`{ 
  "auth_req_id" : "3857f8ff-21b9-48ae-a732-a3bd8128a7ae",
  "expires_in" : 120 
}
`}</code></pre>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Poll the token endpoint (`}<inlineCode parentName="li">{`/oauth2/token`}</inlineCode>{`) to get a response with an ID token.`}<br parentName="li"></br>
        {`The `}<inlineCode parentName="li">{`auth_req_id`}</inlineCode>{` is used to construct a poll request on the token endpoint:`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`# Replace \`AUTH_REQ_ID\` with the id returned by authentication response
# Replace \`$client_assertion\` with your JWT assertion

HTTP POST https://YOUR_DOMAIN.criipto.id/oauth2/token
Content-Type: application/x-www-form-urlencoded

auth_req_id=AUTH_REQ_ID
&grant_type=urn:openid:params:grant-type:ciba
&client_assertion_type=urn:ietf:params:oauth:client-assertion-type:jwt-bearer
&client_assertion=$client_assertion
`}</code></pre>
    <p><em parentName="p"><strong parentName="em">{`Polling Frequency:`}</strong>{` Bear in mind, that the authentication process requires interacting multiple times with the end-user, so the identification process could take some time. Consequently, the token endpoint should be polled every 5 seconds.`}</em></p>
    <p><em parentName="p"><strong parentName="em">{`Token Delivery Modes:`}</strong>{` CIBA allows three token delivery methods: Poll, Ping and Push. Criipto Verify only supports the Poll mode at the moment.`}</em></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`The result of a poll request will depend on the state of the process:`}</li>
    </ol>
    <p>{`On successful user identification, a successful token response is returned, e.g.:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`{
  token_type: 'Bearer',
  expires_in: '120',
  id_token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjgyN0Q5QTNFOTg2MTY0OTVBQzZGRTE3MUFFNkRBM0IzQ0ExNDE5MjEifQ.eyJpc3MiOiJodHRwczovL25hdGFsaWEtZGV2LXRlc3QuY3JpaXB0by5pbyIsImF1ZCI6InVybjpjaWJhIiwiaWRlbnRpdHlzY2hlbWUiOiJzZWJhbmtpZCIsImF1dGhlbnRpY2F0aW9udHlwZSI6InVybjpncm46YXV0aG46c2U6YmFua2lkIiwiYXV0aGVudGljYXRpb25tZXRob2QiOiJ1cm46b2FzaXM6bmFtZXM6dGM6U0FNTDoyLjA6YWM6Y2xhc3NlczpTb2Z0d2FyZVBLSSIsImF1dGhlbnRpY2F0aW9uaW5zdGFudCI6IjIwMjQtMDUtMjJUMTA6NDE6NDEuMzYxWiIsIm5hbWVpZGVudGlmaWVyIjoiYmI5YzIzNjRkZmFlNDRmM2JjZmQ5OTkwNTNkOTRmOWUiLCJzdWIiOiJ7YmI5YzIzNjQtZGZhZS00NGYzLWJjZmQtOTk5MDUzZDk0ZjllfSIsInNlc3Npb25pbmRleCI6ImI2NWYxMWI2LWViYTctNDg4Mi05MDBhLTVmMmQ5M2EzOGRiYSIsInNzbiI6IjE5NjgwMjAyMDU3NSIsIm5hbWUiOiJUZXJuZSBQYXVsc2VuIiwiZ2l2ZW5uYW1lIjoiVGVybmUiLCJnaXZlbl9uYW1lIjoiVGVybmUiLCJzdXJuYW1lIjoiUGF1bHNlbiIsImZhbWlseV9uYW1lIjoiUGF1bHNlbiIsImlwYWRkcmVzcyI6IjE4NS4xNTcuMTM0LjEzNCIsImNvdW50cnkiOiJTRSIsImlhdCI6MTcxNjM3NDUwMSwibmJmIjoxNzE2Mzc0NTAxLCJleHAiOjE3MTYzNzU3MDF9.RVQnlukfoH597uXzE1Gays5DElGzAr8xgOmi7ZWppaL3QPGhV4vK2o6qLhxXg_-FKG9xCwHR6gEhnNzWA3W3B6Q2zJeQTYh9okUvTmmhAFIyDL7lEtfWVVKUKvauDisYVZDjAxJQS_1zbgPEi5I-UJ6_kvMGH-wC13MAD2bZGTGR2dR-ZevBUn7plOt0PKXrIZD3vwxDfebTMPQqX_9SNT5F7GLjCcpeVK-T5LOgmUMFcTAbHvNyklqP5ymRHsZLDw_ib4I7ZqODhR-3uISWo1NvG4Y84iBcqv50WRNlmMUm004LfPw1flM5DNsVyUWCqYW8m7eBEwLp5va-6OQG4w',
  access_token: 'cf1ce646-7fbe-4740-9c56-fe3f0891f6c6'
}
`}</code></pre>
    <p>{`The user has not yet interacted with the Swedish BankID app:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`HTTP/1.1 400 Bad Request
{
  "error": "authorization_pending",
  "error_description": "awaiting first user interaction"
}
`}</code></pre>
    <p>{`The user has been presented with a message requesting to confirm if they can acknowledge that a request for identification is warranted:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`HTTP/1.1 400 Bad Request
{
  "error": "authorization_pending",
  "error_description": "awaiting user request acknowledgement"
}
`}</code></pre>
    <p>{`The user has acknowledged the request, but has not yet identified themselves:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`HTTP/1.1 400 Bad Request
{
  "error": "authorization_pending",
  "error_description": "awaiting user identification"
}
`}</code></pre>
    <p>{`The user did not acknowledge that the identification request warranted or canceled the request before identification was performed:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`HTTP/1.1 400 Bad Request
{
  "error": "access_denied",
  "error_description": "user declined"
}
`}</code></pre>
    <h2>{`Signing text/markdown`}</h2>
    <p>{`Swedish BankID supports signing text and markdown.`}</p>
    <p>{`Text signing is triggered by using the `}<inlineCode parentName="p">{`action`}</inlineCode>{` and `}<inlineCode parentName="p">{`message`}</inlineCode>{` parameters `}<a parentName="p" {...{
        "href": "https://docs.criipto.com/verify/getting-started/oidc-intro/#the-loginhint-parameter"
      }}>{`via login_hint`}</a>{`.`}</p>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`action`}</inlineCode>{` parameter must be set to `}<inlineCode parentName="li">{`sign`}</inlineCode></li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`message`}</inlineCode>{` parameter must be base64 encoded and at most 40,000 characters after base64 encoding.`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`message`}</inlineCode>{` parameter supports markdown text.`}</li>
    </ul>
    <p><em parentName="p">{`Note: Signing produces very large JWTs that are not appropriate for authentication/Bearer header use`}</em></p>
    <p>{`Text signing has a different eID cost than authentication, please `}<a parentName="p" {...{
        "href": "https://www.criipto.com/contact"
      }}>{`contact Criipto sales`}</a>{` if you have questions.`}</p>
    <details>
  <summary><strong>Example</strong></summary>
      <p>{`  `}<inlineCode parentName="p">{`login_hint=action:sign message:VmVuZG9yOgoKIyMgQ29vbCBEZWxpdmVyeQoKQW1vdW50OgoKIyMgMzAwIEVVUgoKLS0tCgpJIGFwcHJvdmUgdGhpcyB0cmFuc2FjdGlvbi4=`}</inlineCode></p>
      <p>{`  Try out the example in `}<a parentName="p" {...{
          "href": "/verify/guides/authorize-url-builder/?acr_values=urn:grn:authn:se:bankid&action=sign&message=Vendor%3A%0A%0A%23%23%20Cool%20Delivery%0A%0AAmount%3A%0A%0A%23%23%20300%20EUR%0A%0A---%0A%0AI%20approve%20this%20transaction."
        }}>{`our URL builder`}</a></p>
      <p>{`  `}<span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "768px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "110.41666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAWABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIDBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHtXhbNKDjYlgf/xAAaEAACAgMAAAAAAAAAAAAAAAAAAQIREBIh/9oACAEBAAEFAqRSFFHcI0KYj//EABURAQEAAAAAAAAAAAAAAAAAABEg/9oACAEDAQE/AWP/xAAVEQEBAAAAAAAAAAAAAAAAAAARIP/aAAgBAgEBPwEj/8QAFxABAAMAAAAAAAAAAAAAAAAAECAxQf/aAAgBAQAGPwIqGP8A/8QAGxABAAMAAwEAAAAAAAAAAAAAAQARITFBUXH/2gAIAQEAAT8htaSVRVuNg9d+sFrZxm9j2SCif//aAAwDAQACAAMAAAAQRD+//8QAFxEBAQEBAAAAAAAAAAAAAAAAAAERMf/aAAgBAwEBPxAlYnH/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8QLlf/xAAcEAEAAgIDAQAAAAAAAAAAAAABABEhYTFBUcH/2gAIAQEAAT8Qy6sUOYEAEDcshps287mvW8h26ieh8G4m+e4NWE2to+rD5oj0LeZ//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Text signing in app",
            "title": "Text signing in app",
            "src": "/static/b2dbe7567fa9142070508b58df6137b2/212bf/se-bankid-signing.jpg",
            "srcSet": ["/static/b2dbe7567fa9142070508b58df6137b2/7809d/se-bankid-signing.jpg 192w", "/static/b2dbe7567fa9142070508b58df6137b2/4ecad/se-bankid-signing.jpg 384w", "/static/b2dbe7567fa9142070508b58df6137b2/212bf/se-bankid-signing.jpg 768w", "/static/b2dbe7567fa9142070508b58df6137b2/4e5ca/se-bankid-signing.jpg 1079w"],
            "sizes": "(max-width: 768px) 100vw, 768px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
    </details>
    <h2>{`Ordering Swedish BankID`}</h2>
    <p>{`To start accepting real users with Swedish BankID, you must first request a certificate to identify your organization.`}</p>
    <p>{`Criipto acts as a reseller for Swedbank, which means that you will have to go through a simple approval process with Swedbank. The process is managed by Criipto.`}</p>

    <p>{`To order the actual certificate simply `}<a href={template} download>{`download this form`}</a>{` and return a filled out copy to `}<a href="mailto:support@criipto.com?subject=Swedish BankID for ..."><a parentName="p" {...{
          "href": "mailto:support@criipto.com"
        }}>{`support@criipto.com`}</a></a></p>
    <h2>{`Next steps`}</h2>
    <p>{`After Criipto has received the filled out form, we send it to Swedbank where it will be reviewed and typically approved within a few business days.`}</p>
    <p>{`Once your organization and your intended use of BankID has been approved we will create and install the actual certificate in your Criipto tenant. and you will be ready to continue the process in the `}<a parentName="p" {...{
        "href": "/verify/guides/production"
      }}>{`getting ready for production guide`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      